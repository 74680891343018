import React, { useContext } from 'react'
import { Entity } from "@parallelpublicworks/entitysync";
import { ImpactContext } from '../../components/Layout'
import ProfileForm from '../../components/ProfileForm'
import { CircularProgress } from '../../components/library'

function Child() {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    const { user } = useContext(ImpactContext)

    if(!user) return <CircularProgress />

    return (
        <Entity type="user--user" source={user} componentId={`${type}-entity`}>
            <ProfileForm member={type} label_prefix="Pet's" />
        </Entity>
    )
}

export default Child
